var headroom;

// Load background images
jQuery(".load-bg-img").each(function (i, obj) {
  loadBgImage(jQuery(this));
});

function loadBgImage(element) {
  var large_img = element.attr("data-bg-large");
  var small_img = element.attr("data-bg-small");

  var bgImg = new Image();
  bgImg.onload = function () {
    element.css("background-image", "url(" + bgImg.src + ")");
    element.addClass("loaded");
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = element.attr("data-bg");
  } else if (jQuery(document).width() > 400) {
    bgImg.src = element.attr("data-bg-small");
  } else {
    bgImg.src = element.attr("data-bg-tiny");
  }
}

// Navigation
jQuery(".dropdown").on("show.bs.dropdown", function () {
  var elem = jQuery(this).find(".dropdown-menu");
  setTimeout(function () {
    elem.addClass("inplace");
  }, 100);
});
jQuery(".dropdown").on("hide.bs.dropdown", function () {
  jQuery(this).find(".dropdown-menu").removeClass("inplace");
});
// Back button for the main menu
jQuery("#menu-primary-navigation .dropdown").on(
  "hide.bs.dropdown",
  function () {
    jQuery(".subnavbar-toggler").removeClass("show");
    setTimeout(function () {
      jQuery(".subnavbar-toggler").removeClass("inplace");
    }, 200);
  }
);
jQuery("#menu-primary-navigation .dropdown").on(
  "show.bs.dropdown",
  function () {
    jQuery(".subnavbar-toggler").addClass("inplace");
    setTimeout(function () {
      jQuery(".subnavbar-toggler").addClass("show");
    }, 200);
  }
);
jQuery(document).ready(function () {
  jQuery(document).on("click", ".dropdown-menu", function (e) {
    e.stopPropagation();
  });
});

current_employee = "";

// Bio popups
jQuery(document).ready(function () {
  jQuery(".modal-trigger").on("click", function () {
    var id = jQuery(this).attr("id");
    console.log(jQuery("#" + id + "-content .mtitle").html());
    //jQuery('#' + id + ' .mtitle').html();
    jQuery(".modal .modal-title").html(
      jQuery("#" + id + "-content .mtitle").html()
    );
    jQuery(".modal .modal-body").html(
      jQuery("#" + id + "-content .mbody").html()
    );

    jQuery(".modal").modal("show");
  });
});

jQuery(document).ready(function () {
  jQuery(".answer.collapse").on("hide.bs.collapse", function () {
    jQuery(this).parent().find("h3").removeClass("showing");
  });

  jQuery(".answer.collapse").on("show.bs.collapse	", function () {
    jQuery(this).parent().find("h3").addClass("showing");
  });

  jQuery(".nav.collapse").on("hide.bs.collapse", function () {
    jQuery(".navbar-container").removeClass("show");
    jQuery(".navbar-toggler").removeClass("show");
    jQuery("html").removeClass("show-nav");
    jQuery("body").removeClass("no-scroll");
    setTimeout(function () {
      jQuery(".navbar-container").removeClass("showing");
      jQuery(".navbar-toggler").removeClass("showing");
      jQuery("html").removeClass("showing-nav");
    }, 100);
  });

  jQuery(".nav.collapse").on("show.bs.collapse	", function () {
    jQuery(".navbar-container").addClass("showing");
    jQuery(".navbar-toggler").addClass("showing");
    jQuery("html").addClass("showing-nav");
    jQuery("body").addClass("no-scroll");

    setTimeout(function () {
      jQuery(".navbar-container").addClass("show");
      jQuery(".navbar-toggler").addClass("show");
      jQuery("html").addClass("show-nav");
    }, 100);
  });

  jQuery(".the-search-form.collapse").on("show.bs.collapse	", function () {
    setTimeout(function () {
      jQuery(".the-search-form input.search-field").focus();
      console.log("Here");
    }, 300);
  });
});

var heroTween = new TimelineMax({ paused: true });
// If we have a hero
if (jQuery(".page-title").length) {
  if (jQuery(".page-title").hasClass("no-img")) {
  } else {
    var bgImg = new Image();
    bgImg.onload = function () {
      jQuery(".page-title").css("background-image", "url(" + bgImg.src + ")");

      setTimeout(function () {
        jQuery(".page-title").addClass("inplace");
      }, 20);
    };

    if (jQuery(document).width() > 800) {
      bgImg.src = jQuery(".page-title").attr("data-bg");
    } else if (jQuery(document).width() > 400) {
      bgImg.src = jQuery(".page-title").attr("data-bg-small");
    } else {
      bgImg.src = jQuery(".page-title").attr("data-bg-tiny");
    }
  }
}

jQuery(".page-title.alternate").imagesLoaded(function () {
  // Animate it for coolness
  jQuery(document).ready(function () {
    // Hero fade in
    var h1 = jQuery(".page-title h1");
    var strong = jQuery(".page-title strong");
    var p = jQuery(".page-title p.desc");
    var butt = jQuery(".page-title a.butt");
    var heroTween = new TimelineMax();

    heroTween
      .fromTo(h1, 0.8, { opacity: 0, y: 10  }, { delay: 0.2, opacity: 1, y: 0 }, .22, "-=.15")
      .fromTo(strong, 0.8, { opacity: 0, y: 10 }, { delay: 0, opacity: 1, y: 0 }, .42, "-=.15")
      .fromTo(p, 0.8, { opacity: 0, y: 10 }, { delay: 0, opacity: 1, y: 0 }, 0.62, "-=.15")
      .fromTo(butt, 0.8, { opacity: 0, y: 10 }, { delay: 0, opacity: 1, y: 0 }, .82, "-=.15")
      .play();
    setTimeout(function () {
      jQuery(".page-title").addClass("inplace");
    }, 200);
  });
});



/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        jQuery(
          ".marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p"
        ).each(function () {
          var string = jQuery(this).html();
          string = string.replace(/ ([^ ]*)$/, "&nbsp;$1");
          jQuery(this).html(string);
        });

        var viewport_width = jQuery(document).width();
        var offset = 40;
        if (viewport_width < 1000) {
          offset = 40;
        }

        var myElement = document.querySelector("header");
        // construct an instance of Headroom, passing the element
        headroom = new Headroom(myElement, {
          offset: offset,
          tolerance: {
            down: 11,
            up: 3,
          },
          // callback when pinned, `this` is headroom object
          onPin: function () {
            jQuery('html').addClass('hr--pinned');
    },
    // callback when unpinned, `this` is headroom object
          onUnpin: function () {
            jQuery('html').removeClass('hr--pinned');
    }
        });
        // initialise
        headroom.init();

        // Secondary navigation
        /*
          var secondary_nav = document.querySelector(".secondary-nav nav");
          // construct an instance of Headroom, passing the element
          var headroom_secondary = new Headroom(secondary_nav, {
            offset: 100,
            tolerance: {
              down: 11,
              up: 3
            }
          });
          // initialise
          headroom_secondary.init();
          */
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function getScrollTop() {
  if (typeof pageYOffset !== "undefined") {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;
var promo_in_place = false;

var sticky_nav_from_top = 0;
var sticky_nav_stuck = false;

// Update the navigation based on the width of the viewport.
function updateNav() {
  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass("scrollnav");
    darker_nav = true;
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass("scrollnav");
    darker_nav = false;
  }

  if (jQuery('.sticky-nav-container').length > 0) {
    sticky_nav_from_top = jQuery('.sticky-nav-container').offset().top;
  }


  if (sticky_nav_from_top > 0) {
    if (!sticky_nav_stuck && from_top >= sticky_nav_from_top) {
      jQuery('.sticky-nav-container').addClass('nav--pinned');
      sticky_nav_stuck = true;
    }
    else if (sticky_nav_stuck && from_top < sticky_nav_from_top) {
      jQuery('.sticky-nav-container').removeClass('nav--pinned');
      sticky_nav_stuck = false;
    }
  }

}


function windowResizeTasks() { 
  //if (jQuery('.sticky-nav-container').length > 0) {
  //  sticky_nav_from_top = jQuery('.sticky-nav-container').offset().top;
  //}
}

jQuery(document).ready(function () {

  windowResizeTasks();

  updateNav();
  jQuery(window).scroll(jQuery.throttle(65, updateNav));
  jQuery(window).on("debouncedresize", function (event) {
    // Your event handler code goes here.
    updateNav();
  });

  jQuery("a.list-group-item").click(function (e) {
    e.preventDefault();

    event.preventDefault();
    //calculate destination place
    var dest = 0;
    if (
      jQuery(this.hash).offset().top >
      jQuery(document).height() - jQuery(window).height()
    ) {
      dest = jQuery(document).height() - jQuery(window).height();
    } else {
      //dest = jQuery(this.hash).offset().top;
      //dest = jQuery(this.hash).position().top;
      dest = jQuery(this.hash).get(0).offsetTop;

      console.log("Skipping to: " + dest);
    }

    jQuery(".scrollspy").scrollTop(dest);
    jQuery("a.list-group-item").removeClass("active");
    jQuery("a.list-group-item").removeClass("active");
    jQuery(this).addClass("active");

    setTimeout(function () {
      jQuery(this).addClass("active");
    }, 20);
  });
});

//
// Parallax
//

jQuery(document).ready(function () {
  // Vendor nav
  jQuery("nav.vendor-nav li").on("click", function () {
    jQuery("ul.vendors").addClass("loading");

    var id = jQuery(this).find("button").attr("id");

    jQuery("nav.vendor-nav li").removeClass("active");
    jQuery(this).addClass("active");

    jQuery.get("vendors?cat=" + id, function (data) {
      setTimeout(function () {
        jQuery("#vendors-list").html(data);
        vendorEvents();
      }, 5);

      setTimeout(function () {
        jQuery("ul.vendors").removeClass("loading");
      }, 10);
    });
  });
});

jQuery(document).ready(function () {
  // Portfolio masonry layout
  /*
  var $grid = jQuery(".m-grid").masonry({
    // options
    itemSelector: ".m-grid-item",
    percentPosition: true,
    columnWidth: ".m-grid-sizer"
  });

  // layout Masonry after each image loads
  $grid.imagesLoaded().progress(function() {
    $grid.masonry("layout");
  });
  */
  //jQuery(".m-grid").featherlightGallery();
});

/* ================================
 * Map locations
 * ============================= */
jQuery(document).ready(function () {
  jQuery(document).on("facetwp-loaded", function () {
    if ("" == FWP.buildQueryString()) {
      jQuery(".reset-button").addClass("disabled");
      jQuery(".reset-button").attr("disabled", "disabled");
    } else {
      jQuery(".reset-button").removeClass("disabled");
      jQuery(".reset-button").removeAttr("disabled", "disabled");
    }

    jQuery(".facetwp-template .load-bg-img").each(function (i, obj) {
      loadBgImage(jQuery(this));
    });

    // Product page doc view
    if (jQuery(".post-type-archive-advisors").length > 0) {
      // Get active filters
      var active_filters = 0;

      Object.keys(FWP.facets).forEach(function (key, index) {
        // key: the name of the object key
        // index: the ordinal position of the key within the object
        if (FWP.facets[key].length > 0) active_filters++;
      });

      // If we're on a product page and there are no facets select, blank out the results.
      if (active_filters == 0) {
        jQuery(".facetwp-template").html(
          "<div class='col-md-12'><div class='no-filters'>" +
            vars.advisors_plug +
            "</div></div>"
        );
        jQuery(".view-more-posts").addClass("disabled");
      } else if (active_filters > 0 && FWP.settings.pager.total_rows <= 0) {
        jQuery(".facetwp-template").html(
          "<div class='col-md-12'><div class='no-filters'>No advisors found.</div></div>"
        );
        jQuery(".view-more-posts").addClass("disabled");
      } else {
        jQuery(".view-more-posts").removeClass("disabled");
        jQuery(".advisor-col").addClass("show");
      }
    }
  });

  if (window.FWP_MAP !== undefined) {
    jQuery(".view-on-map").on("click", function (e) {
      var post_id = jQuery(this).attr("data-map-location-id");
      if (
        FWP_MAP.markerLookup[post_id] !== null &&
        FWP_MAP.markerLookup[post_id] !== undefined
      ) {
        //console.log(markers[]);
        var index = FWP_MAP.markerLookup[post_id];
        var this_marker = FWP_MAP.markersArray[index];
        FWP_MAP.infoWindow.setContent(this_marker.content);
        FWP_MAP.infoWindow.setPosition(this_marker.position);
        FWP_MAP.infoWindow.open(FWP_MAP.map, this_marker);
      }
    });
  }

  jQuery(document).on("facetwp-refresh", function () {
    jQuery(".fwp-elements").addClass("loading");
  });

  jQuery(document).on("facetwp-loaded", function () {
    jQuery(".fwp-elements").removeClass("loading");
    //jQuery('.facetwp-radius-dropdown').niceSelect();
  });
});

jQuery(document).ready(function () {
  jQuery(document).on("facetwp-refresh", function () {
    jQuery(".facetwp-template").addClass("loading");
  });

  jQuery(document).on("facetwp-loaded", function () {
    jQuery(".facetwp-template").removeClass("loading");
  });
});

// Accordian stuff
jQuery(document).ready(function () {
  jQuery(".accordian.device-text").on("hidden.bs.collapse", function (e) {
    var id = e.target.id;

    // Hide others
    jQuery(".laptop-with-text .screenshot img").removeClass("show");

    console.log(id);
    jQuery("#" + id + "-dv").removeClass("show");
  });

  jQuery(".accordian.device-text").on("shown.bs.collapse", function (e) {
    var id = e.target.id;

    // Hide others
    jQuery(".laptop-with-text .screenshot img").removeClass("show");

    console.log(id);
    jQuery("#" + id + "-dv").addClass("show");
  });

  // the current open accordion will not be able to close itself
  jQuery('.accordion button[data-toggle="collapse"]').on("click", function (e) {
    if (!jQuery(this).hasClass("collapsed")) {
      e.stopPropagation();
    }
  });
});

function updateTestimonialScroller() {
  if (jQuery(".testimonials-block").length > 0) {
    setTimeout(function () {
      var id = jQuery(".t-controls").attr("id");
      var leftPos = jQuery("#" + id + "-container").scrollLeft();

      // If we are all the way to left hide that arrow.
      if (leftPos <= 0) {
        jQuery(".t-controls a.back").addClass("out");
      } else {
        jQuery(".t-controls a.back").removeClass("out");
      }

      // Check if we're scrolled all the way to right?
      var viewable_width = jQuery(".testimonials-block .row").width();
      var scrollable_width = jQuery(".testimonials-block .row")[0].scrollWidth;
      //console.log("Scrollable width is " + scrollable_width);
      //console.log("Viewable width " + viewable_width);
      //console.log("Left position is  " + leftPos);

      if (leftPos + viewable_width + 100 >= scrollable_width) {
        jQuery(".t-controls a.forward").addClass("out");
      } else {
        jQuery(".t-controls a.forward").removeClass("out");
      }
    }, 210);
  }
}

// Testimonials scroll
jQuery(document).ready(function () {
  updateTestimonialScroller();
  jQuery(".testimonials-block .container-fluid").scroll(
    jQuery.throttle(65, updateTestimonialScroller)
  );
  jQuery(".t-controls a.forward").click(function () {
    var id = jQuery(this).parent().attr("id");
    var leftPos = jQuery("#" + id + "-container").scrollLeft();

    jQuery("#" + id + "-container").animate({ scrollLeft: leftPos + 300 }, 200);

    updateTestimonialScroller();

    return false;
  });

  jQuery(".t-controls a.back").click(function () {
    var id = jQuery(this).parent().attr("id");
    var leftPos = jQuery("#" + id + "-container").scrollLeft();
    console.log(id);

    jQuery("#" + id + "-container").animate({ scrollLeft: leftPos - 300 }, 200);

    updateTestimonialScroller();

    return false;
  });
});

function createCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

/* ================================
 * Cookie notice
 * ============================= */
jQuery(document).ready(function () {
  var allow_cookies = readCookie("allow_cookies");
  if (allow_cookies !== "yes") {
    jQuery(".cookie-notice").addClass("display");
    jQuery(".cookie-notice").addClass("inplace");
  } else {
    console.log("Cookies allowed.");
  }

  jQuery("#allow-cookies").click(function (event) {
    createCookie("allow_cookies", "yes", 7);

    setTimeout(function () {
      jQuery(".cookie-notice").removeClass("display");
    }, 200);
    jQuery(".cookie-notice").removeClass("inplace");

    return false;
  });
});

// Tooltips
jQuery(document).ready(function () {
  jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
  });
});

jQuery(document).ready(function () {
  jQuery(".popover-info").popover();
});





// Fix bootstrap jump
function carouselNormalization() {
  var items = jQuery('.grid-slider .carousel .carousel-item'), //grab all slides
      heights = [], //create empty array to store height values
      tallest; //create variable to make note of the tallest slide
  
  if (items.length) {
      function normalizeHeights() {
          items.each(function() { //add heights to array
              heights.push(jQuery(this).height()); 
          });
          tallest = Math.max.apply(null, heights); //cache largest value
          items.each(function() {
            jQuery(this).css('min-height',tallest + 'px');
          });
      };
      normalizeHeights();
  
      jQuery(window).on('resize orientationchange', function () {
          tallest = 0, heights.length = 0; //reset vars
          items.each(function() {
            jQuery(this).css('min-height','0'); //reset min-height
          }); 
          normalizeHeights(); //run it again 
      });
  }
}
jQuery(document).ready(function() {
  carouselNormalization();
});






jQuery(document).ready(function() {
  // Add smooth scrolling to all links
  jQuery(".sticky-nav a").on("click", function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        jQuery("html, body").animate(
          {
            scrollTop: jQuery(hash).offset().top
          },
          800,
          function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
            // Close main nav.
            headroom.unpin();
          }
        );
      }
  });
});