jQuery(document).ready(function() {
  var controller = new ScrollMagic.Controller();

  var animateBackgroundWord = Array.prototype.slice.call(
    document.querySelectorAll(".snug-to-margin")
  );

  var self = this;

  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll(".grid-fade")
  );

  animateGrid.forEach(function(self) {
    var grid = self.querySelectorAll(".grid-fade .row .grid-item");

    var gridScene = new TimelineMax();

    gridScene.fromTo(
      grid,
      0.8,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.1
      }
    );

    var grid_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(gridScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });

  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll(".text-fade")
  );

  animateGrid.forEach(function(self) {
    var two_column_headings = self.querySelectorAll(
      ".text-fade h1, .text-fade h2, .text-fade h3, .text-fade h4, .text-fade .hcap"
    );
    var two_column_p = self.querySelectorAll(
      ".text-fade p, .text-fade ul, .text-fade ol"
    );
    var two_column_button = self.querySelectorAll(".text-fade a.butt");

    var twoColumnScene = new TimelineMax();

    twoColumnScene
      .fromTo(
        two_column_headings,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        }
      )
      .fromTo(
        two_column_p,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
      )
      .fromTo(
        two_column_button,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
      );

    var two_column_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(twoColumnScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });

  // ANimate the timeline
  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll("section.timeline .row .timeline-entry")
  );

  animateGrid.forEach(function(self) {
    //var grid = self.querySelectorAll("section.timeline .row .timeline-entry");
    var entry = self;

    var timelineScene = new TimelineMax();

    timelineScene.fromTo(
      entry,
      0.8,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.1
      }
    );

    var tl_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(timelineScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });



  // Fade in a two column
  var animateTwoCol = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-fade")
  );

  animateTwoCol.forEach(function(self) {
    var two_column_headings = self.querySelectorAll(
      ".two-col-fade h1, .two-col-fade h2, .two-col-fade h3, .two-col-fade h4, .two-col-fade .hcap"
    );
    var two_column_p = self.querySelectorAll(
      ".two-col-fade .desc, .two-col-fade p, .two-col-fade ul, .two-col-fade ol"
    );
    var two_column_button = self.querySelectorAll(".two-col-fade a.butt");

    var two_column_hr = self.querySelectorAll(".two-col-fade hr.mini");

    var twoColumnScene = new TimelineMax();

    twoColumnScene
      .fromTo(
        two_column_headings,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        }
      )
      .fromTo(
        two_column_p,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
    )
      .fromTo(
        two_column_button,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
      )
      .fromTo(
        two_column_hr,
        .8,
        { opacity: 0, width: 0 },
        {
          opacity: 1,
          width: 30
        },
        0.3,
        "-=.3"
      );

    var two_column_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 240
    })
      .setTween(twoColumnScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });





});
